const prefix = 'mamla_container'
const NlFilePErms = {
    file: {
        menu: `${prefix}.view_nl_file_menu`,
        add: `${prefix}.add_nl_files`,
        view: `${prefix}.view_nl_files`,
        list: `${prefix}.view_nl_files`,
        delete: `${prefix}.delete_nl_file`,
        own_branch: `${prefix}.view_own_branch_nl_files`,
        own_zone: `${prefix}.view_own_zone_files`,
    },
    involved_amount: {
        edit: `${prefix}.change_involved_amnt_nl_files`,
    },
    dealing_officer: {
        edit: `${prefix}.change_dealing_officer_nl_files`,
    }
}

export default NlFilePErms;