const nls_perms = {
    subject: {
        menu: "non_loan_subjects.nls_menu",
        add: "non_loan_subjects.add_subjects",
        list: "non_loan_subjects.view_subjects",
        edit: "non_loan_subjects.change_subjects",
        delete: "non_loan_subjects.delete_subjects",
    },
    owner: {
        menu: "non_loan_subjects.nls_owner_menu",
        add: "non_loan_subjects.add_owner",
        list: "non_loan_subjects.view_owner",
        edit: "non_loan_subjects.change_owner",
        delete: "non_loan_subjects.delete_owner",
    }
}

export default nls_perms;