<script>
import nlFileList from "@/components/nl_file/sub_components/fileList.vue"
import nls_perms from "@/constants/permissions/nls_perms";

export default {
  name: "nlFileListpage",
  components: {nlFileList},
  methods: {},
  computed: {
    show_own_branch() {
      return this.$store.getters.permissionCheckers(nls_perms.subject.list)
    }
  },
  mounted: () => {
    document.title = 'Agrani Bank Legal Suit System | Non Loan File List'
    window.scrollTo(0, 0);
  }
}
</script>

<template>
  <nlFileList v-if="show_own_branch" :own_branch="$store.state.branch_data" key="own_branch"
              :office_id="$store.state.branch_data.id"></nlFileList>

</template>

<style scoped>

</style>