<template>
  <v-container v-if="has_view_permission" class="px-0">
    <v-data-table
        :headers="column_headers"
        :items="table_items"
        sort-by="tracking_number"
        class="elevation-5 yellow lighten-2"
        :loading="table_loading"
        loading-text="Loading... Please wait"
        item-key="id"
        name="subjects_table"
        :search="search"
    >
      <template v-slot:top>
        <v-card-title
            flat
        >
          {{ table_title }}
          <v-spacer></v-spacer>
          <v-btn :loading="reload_btn" @click="get_lists" class="ma-1">
            <v-icon>
              mdi-reload
            </v-icon>
            Reload Data Table
          </v-btn>
          <v-btn v-if="show_search_form_btn" :loading="reload_btn" @click="handle_another_office" class="ma-1">
            <v-icon>
              mdi-reload
            </v-icon>
            Search For another office
          </v-btn>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="mx-3"
          ></v-text-field>
        </v-card-title>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row align="center" justify="center">
          <v-col cols="4">
            <router-link :to="{name:'nlFileDetails', params:{fileid:item.id}}"
                         style="text-decoration: none; color: inherit">

              <v-btn
                  color="orange"
                  small
                  elevation="9"
                  class="font-weight-bold"
              >
                <v-icon class="px-2">
                  mdi-file-multiple
                </v-icon>
                Details
              </v-btn>
            </router-link>
          </v-col>
          <v-col cols="4">
            <v-btn
                color="orange"
                small
                elevation="9"
                :loading="report_btn_loading"
                class="font-weight-bold"
                @click="fetchTopSheet(item.id, item.nls_subject.name)"
            >
              <v-icon class="px-2">
                mdi-file-multiple
              </v-icon>
              Top-sheet
            </v-btn>

          </v-col>
          <v-col cols="4" color="orange">
            <delete_dialog v-if="delete_button" @form_status="get_lists" :passed_item="item"
                           :dialogue_text="`Are you sure you want to Delete File# ${item.tracking_number}?`"
                           :url="`${delete_url.replace('{fileid}', item.id)}`"
                           activator_name="Delete" activator_icon="mdi-delete"
                           tooltip_text="Delete"
                           snackbar_text="File#{value} has been deleted"
                           action="delete"
                           big_btn="true" color="orange" :small=true
            ></delete_dialog>

          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>


import axios from "axios";
import NlFile from "@/constants/urls/nl_file";
import delete_dialog from "@/components/mamla/loan_mamla/loan_mamla_sub_components/delete_dialog";
import report_perms from "@/constants/permissions/report_permissions";
import report_urls from "@/constants/reports/report";
import parsing_functions from "@/constants/util_functions/parsing_functions"
import nl_file_perms from "@/constants/permissions/nl_file_perms";
import createAxiosClient from "@/helpers/axios_helper";

export default {
  name: "nlFileList",
  props: ['table_title', 'office_id', 'show_search_form_btn'],
  components: {delete_dialog},
  computed: {
    has_view_permission() {
      return this.$store.getters.permissionCheckers(nl_file_perms.file.list)
    },
    details_button() {
      return this.$store.getters.permissionCheckers(nl_file_perms.file.view)
    },
    delete_button() {
      return this.$store.getters.permissionCheckers(nl_file_perms.file.delete)
    },
    report_btn() {
      return this.$store.getters.permissionCheckers(report_perms.view_topsheet)
    }
  },
  data: () => ({
    table_items: [],
    skeleton_loader_attrs: {
      boilerplate: true,
    },
    show_snackbar: false,
    snackbar_text: '',
    list_url: NlFile.file.list,
    delete_url: NlFile.file.delete,
    table_loading: false,
    reload_btn: false,
    search: '',
    report_btn_loading: false,
    topsheet_data: null,
    column_headers: [
      {
        text: 'Tracking Number',
        align: 'center',
        filterable: true,
        value: 'tracking_number',
      },
      {
        text: 'Subject Name',
        align: 'center',
        filterable: true,
        value: 'borrower.subject_name',
      },
      {
        text: 'Branch Code',
        align: 'center',
        filterable: true,
        value: 'branch.code'
      },
      {
        text: 'Actions',
        align: 'center',
        filterable: false,
        value: 'actions',
      },
    ],
  }),
  methods: {
    get_lists() {
      let client = createAxiosClient()
      let self = this

      this.reload_btn = true
      this.table_loading = true
      this.table_items = []
      if (this.office_id) {
        this.list_url = this.list_url.replace("{office_id}", this.office_id)
      }
      client.get(this.list_url).then((resp) => {
        this.table_items = resp.data.data
        this.$store.commit('setJWT', resp.data.key)
        self.table_loading = false
        this.reload_btn = false
      }).catch((errResp) => {
        console.log(errResp)
      })
    },
    details_page(value, obj) {
      this.$router.push({name: 'LoanMamlaDetails', params: {fileid: obj}})
    },
    fetchTopSheet(file_id, name) {
      this.axios_conf
      let config = {
        headers: {
          multi: 'true'
        }
      }
      this.report_btn_loading = true
      this.reload_btn = true
      axios.get(report_urls.topsheet.replace("{file_id}", file_id), config).then((resp) => {
        this.topsheet_data = resp.data.report
        this.$store.commit('setJWT', resp.data.key)
        // let blob = this.hextoblob(this.topsheet_data)
        let blob = parsing_functions.hex2blob(this.topsheet_data, "application/pdf")
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = "Topsheet_" + name + ".pdf"
        link.click()
        URL.revokeObjectURL(link.href)
        this.reload_btn = false
        this.report_btn_loading = false
      })
    },
    handle_another_office() {
      this.$emit("select_another_office", null)
    }
  },
  mounted() {
    this.get_lists()
  },
  watch: {}
}
</script>

<style>
div[name="subjects_table"] > div > table > tbody > tr:hover {
  background: #82B1FF !important;
}
</style>